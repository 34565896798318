export const ERROR_CODES: { [key: string]: string } = {
  "auth/email-already-in-use": "This email is already in use. Switch to sign in mode, or create a different account.",
};

export const PLAN_KEYS: { [key: string]: string } = {
  PREMIUM: "PREMIUM",
};

export type PlanKey = "MINI_ARTIST" | "CREATIVE_KID" | "YOUNG_DA_VINCI" | "MASTER_CRAFTER" | "PICASSO";

export const PLAN_PRICE_IDS: Record<PlanKey, string | undefined> = {
  MINI_ARTIST: process.env.STRIPE_MINI_ARTIST,
  CREATIVE_KID: process.env.STRIPE_CREATIVE_KID,
  YOUNG_DA_VINCI: process.env.STRIPE_YOUNG_DA_VINCI,
  MASTER_CRAFTER: process.env.STRIPE_MASTER_CRAFTER,
  PICASSO: process.env.STRIPE_PICASSO,
};

export const plans = [
  { key: "MINI_ARTIST", name: "Mini Artist", price: 1, credits: 5, popular: false },
  { key: "CREATIVE_KID", name: "Creative Kid", price: 9, credits: 50, popular: false },
  { key: "YOUNG_DA_VINCI", name: "Young Da Vinci", price: 15, credits: 100, popular: true },
  { key: "MASTER_CRAFTER", name: "Master Crafter", price: 49, credits: 500, popular: false },
  { key: "PICASSO", name: "Picasso", price: 80, credits: 1000, popular: false },
];
