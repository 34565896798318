import { FirebaseError } from "firebase/app";
import { getAuth, signOut as firebaseSignOut } from "firebase/auth";
import firebase_app from "../config";
import Router from "next/router";

const auth = getAuth(firebase_app);

interface CustomAuthError {
  code: string;
  message: string;
}

export async function signOut(): Promise<{ result: boolean; error: CustomAuthError | null }> {
  let result = false;
  let error: CustomAuthError | null = null;

  try {
    await firebaseSignOut(auth);
    Router.push("/");
    result = true; // Sign out successful
  } catch (e) {
    if (e instanceof FirebaseError) {
      // Handle FirebaseError
      error = { code: e.code, message: e.message };
    } else {
      // Handle unexpected errors
      error = { code: "unknown_error", message: "An unknown error occurred" };
    }
  }

  return { result, error };
}
