"use client";

import { GeneratedPage, User } from "@/constants/types";
import firebase_app from "@/firebase/config";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import React, { ReactNode, useContext, useEffect, useState } from "react";

const auth = getAuth(firebase_app);

// Define the extended User type
// Define the shape of the context data
interface AuthContextType {
  user: User | null;
  loading: boolean;
  refreshUser: () => Promise<void>;
}

// Create the context with a default value
export const AuthContext = React.createContext<AuthContextType>({
  user: null,
  loading: true,
  refreshUser: async () => {},
});

// Hook to use the auth context
export const useAuthContext = () => useContext(AuthContext);

interface AuthContextProviderProps {
  children: ReactNode;
}

// Define the type for the additional user data
interface AdditionalUserData {
  stripeCustomerId?: string;
  subscriptionId?: string;
  plan?: string;
  generatedColoringPages?: GeneratedPage[];
  credits?: number;
}

// AuthContextProvider component
export const AuthContextProvider = ({ children }: AuthContextProviderProps) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  const refreshUser = async (uidOverride?: string) => {
    const uid = uidOverride || user?.uid;

    if (uid) {
      const additionalUserData = await fetchAdditionalUserData(uid);
      setUser((prevUser) => (prevUser ? { ...prevUser, ...additionalUserData } : null));
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        // Fetch additional user data from Firestore
        const additionalUserData = await fetchAdditionalUserData(firebaseUser.uid);
        const { stripeCustomerId, subscriptionId, plan, generatedColoringPages, credits } = additionalUserData;

        const { uid, email } = firebaseUser;
        // Create an extended user object with additional properties
        const extendedUser: User = {
          ...(uid ? { uid } : {}),
          ...(generatedColoringPages ? { generatedColoringPages } : {}),
          ...(email ? { email } : {}),
          ...(stripeCustomerId ? { stripeCustomerId } : {}),
          ...(subscriptionId ? { subscriptionId } : {}),
          ...(plan ? { plan } : {}),
          ...(credits ? { credits } : {}),
        };

        setUser(extendedUser);
      } else {
        setUser(null);
      }
      setLoading(false);
    });

    // Clean up the subscription
    return () => unsubscribe();
  }, []);

  return <AuthContext.Provider value={{ user, loading, refreshUser }}>{children}</AuthContext.Provider>;
};

// Function to fetch additional user data from Firestore
async function fetchAdditionalUserData(userId: string): Promise<AdditionalUserData> {
  const firestore = getFirestore(firebase_app);
  const userRef = doc(firestore, "users", userId);
  const docSnap = await getDoc(userRef);

  if (docSnap.exists()) {
    const userData = docSnap.data();
    return {
      ...(userData.stripeCustomerId ? { stripeCustomerId: userData.stripeCustomerId } : {}),
      ...(userData.generatedColoringPages ? { generatedColoringPages: userData.generatedColoringPages } : {}),
      ...(userData.subscriptionid ? { subscriptionid: userData.subscriptionid } : {}),
      ...(userData.plan ? { plan: userData.plan } : {}),
      ...(userData.credits ? { credits: userData.credits } : {}),
    };
  }

  return {};
}
