"use client";
import React, { ReactNode, useContext, useState } from "react";

// Define the shape of the context data
interface FunContextType {
  preSignUpPrompt: string | null;
  setPreSignUpPrompt: (prompt: string | null) => void;
  preSignUpImg: File | null;
  setPreSignUpImg: (img: File | null) => void;
}

// Create the context with a default value
const FunContext = React.createContext<FunContextType>({
  preSignUpPrompt: null,
  setPreSignUpPrompt: () => {},
  preSignUpImg: null,
  setPreSignUpImg: () => {},
});

// Hook to use the fun context
export const useFunContext = () => useContext(FunContext);

interface FunContextProviderProps {
  children: ReactNode;
}

// FunContextProvider component
export const FunContextProvider = ({ children }: FunContextProviderProps) => {
  const [preSignUpPrompt, setPreSignUpPrompt] = useState<string | null>(null);
  const [preSignUpImg, setPreSignUpImg] = useState<File | null>(null);

  return (
    <FunContext.Provider value={{ preSignUpPrompt, setPreSignUpPrompt, preSignUpImg, setPreSignUpImg }}>
      {children}
    </FunContext.Provider>
  );
};
