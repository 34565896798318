"use client";
import { Drawer, Button, Box, ActionIcon, Text } from "@mantine/core";
import ContactForm from "../ContactForm";
import { useDisclosure } from "@mantine/hooks";
import { IconMessage } from "@tabler/icons-react";
import { useParams } from "next/navigation";
import ColorSchemeToggle from "../ColorSchemeToggle";
import { useAuthContext } from "@/context/authContext";

function ContactWidget() {
  const [opened, { open, close }] = useDisclosure(false);
  const params = useParams();
  const { user } = useAuthContext();
  const isPublicProfile = !!params?.username;

  return (
    <Box bottom="1rem" pos="fixed" right="1rem">
      <Drawer opened={opened} onClose={close} title="Contact Us">
        <Text fz="sm" mb="sm" c="dimmed">
          Question? Feature Request? Just want to tell us that we're cool? Whatever the case - send us a message here!
          Note: the only way we can get back to you is if you provide a valid email address.
        </Text>
        <ContactForm />
      </Drawer>
      {(isPublicProfile || user) && <ColorSchemeToggle />}
      <ActionIcon onClick={open} size="md" variant="subtle" aria-label="contact button">
        <IconMessage />
      </ActionIcon>
    </Box>
  );
}

export default ContactWidget;
