import { OGResponse } from "@/constants/types";
import { getAuth } from "firebase/auth";

import axios from "axios";
import { getStorage } from "firebase/storage";

export async function extractMetaData(url: string): Promise<OGResponse> {
  try {
    const response = await axios.get(`/api/extractMetadata?url=${encodeURIComponent(url)}`);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch meta tags", error);
    throw new Error("Failed to fetch meta tags");
  }
}

export async function createStripeSession(plan: string): Promise<string> {
  try {
    const auth = getAuth();
    const currentUser = auth.currentUser;

    if (currentUser) {
      const idToken = await currentUser.getIdToken(true);

      const response = await axios.post(
        "/api/stripe/createStripeSession",
        { plan },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
        }
      );

      return response.data?.sessionUrl;
    } else {
      return "";
    }
  } catch (error) {
    console.log("error", error);
    return "";
  }
}

export async function getManageSubscriptionLink(): Promise<string> {
  try {
    const auth = getAuth();
    const currentUser = auth.currentUser;

    if (currentUser) {
      const idToken = await currentUser.getIdToken(true);

      const response = await axios.post("/api/stripe/getManageSubscriptionLink", null, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`,
        },
      });

      return response.data?.url;
    } else {
      console.log("User not authenticated");
      return "";
    }
  } catch (error) {
    console.log("error", error);
    return "";
  }
}

export async function deleteUserSsr(): Promise<boolean> {
  try {
    const auth = getAuth();
    const currentUser = auth.currentUser;

    if (currentUser) {
      const idToken = await currentUser.getIdToken(true);

      const response = await axios.post("/api/ssr/deleteUser", null, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`,
        },
      });

      return response.data?.success;
    } else {
      console.error("User not authenticated");
      return false;
    }
  } catch (error) {
    console.error("Error deleting user:", error);
    return false;
  }
}

export async function createUserSsr(): Promise<boolean> {
  try {
    const auth = getAuth();
    const currentUser = auth.currentUser;

    if (currentUser) {
      const idToken = await currentUser.getIdToken(true);

      const response = await axios.post("/api/ssr/createUser", null, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`,
        },
      });

      return response.data?.success;
    } else {
      console.error("User not authenticated");
      return false;
    }
  } catch (error) {
    console.error("Error creating user:", error);
    return false;
  }
}

export async function deleteStripeCustomer(): Promise<boolean> {
  try {
    const auth = getAuth();
    const currentUser = auth.currentUser;

    if (currentUser) {
      const idToken = await currentUser.getIdToken(true);

      const response = await axios.post("/api/stripe/deleteStripeCustomer", null, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`,
        },
      });

      return response.data?.success;
    } else {
      console.error("User not authenticated");
      return false;
    }
  } catch (error) {
    console.error("Error deleting Stripe customer:", error);
    return false;
  }
}

export async function convertAffiliateLink(url: string): Promise<string> {
  try {
    const auth = getAuth();
    const currentUser = auth.currentUser;

    if (currentUser) {
      const idToken = await currentUser.getIdToken(true);

      const response = await axios.post(
        "/api/convertAffiliateLink",
        { url },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
        }
      );

      return response.data.affiliateUrl;
    } else {
      throw new Error("User not authenticated");
    }
  } catch (error) {
    console.error("Error converting affiliate link:", error);
    throw error;
  }
}

// helpers/clientApis.ts
export async function logLinkClick(itemId: string, userId: string) {
  try {
    const response = await fetch("/api/externalLink", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ itemId, userId }),
    });

    if (!response.ok) {
      throw new Error(`Failed to log link click: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error logging link click:", error);
    throw error;
  }
}

export async function fetchColoringPageImage(imagePath: string): Promise<Blob> {
  try {
    const response = await axios.get(`/api/downloadColoringPage`, {
      params: {
        imagePath: encodeURIComponent(imagePath),
      },
      responseType: "blob",
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching coloring page image:", error);
    throw error;
  }
}

export async function generateColoringPage(prompt: string): Promise<string> {
  try {
    const auth = getAuth();
    const currentUser = auth.currentUser;

    if (currentUser) {
      const idToken = await currentUser.getIdToken(true);

      const response = await axios.post(
        "/api/generateColoringPage",
        { prompt, typeOverride: "RPL" },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
        }
      );

      return response.data?.imageUrl;
    } else {
      throw new Error("User not authenticated");
    }
  } catch (error) {
    console.error("Error generating coloring page:", error);
    throw error;
  }
}

export async function removeBackground(imageUrl: string): Promise<string> {
  try {
    const auth = getAuth();
    const currentUser = auth.currentUser;

    if (currentUser) {
      const idToken = await currentUser.getIdToken(true);

      const response = await axios.post(
        "/api/removeBackground",
        { imageUrl },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
        }
      );

      return response.data?.imageUrl;
    } else {
      throw new Error("User not authenticated");
    }
  } catch (error) {
    console.error("Error removing background:", error);
    throw error;
  }
}

export async function regenerateImage(imageUrl: string, prompt: string): Promise<string> {
  try {
    const auth = getAuth();
    const currentUser = auth.currentUser;

    if (currentUser) {
      const idToken = await currentUser.getIdToken(true);

      const response = await axios.post(
        "/api/regenerateColoringPage",
        { imageUrl, prompt },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
        }
      );

      return response.data?.imageUrl;
    } else {
      throw new Error("User not authenticated");
    }
  } catch (error) {
    console.error("Error inpainting image:", error);
    throw error;
  }
}

export async function inpaintImage(imageUrl: string, maskData: string, prompt: string): Promise<string[]> {
  try {
    const auth = getAuth();
    const currentUser = auth.currentUser;

    if (currentUser) {
      const idToken = await currentUser.getIdToken(true);

      const response = await axios.post(
        "/api/inpaintImage",
        { imageUrl, maskData, prompt },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
        }
      );

      return response.data?.imageUrls;
    } else {
      throw new Error("User not authenticated");
    }
  } catch (error) {
    console.error("Error inpainting image:", error);
    throw error;
  }
}

export async function adminUpdateImageInStorage(
  originalImagePath: string,
  newImagePath: string,
  newPrompt: string
): Promise<void> {
  try {
    const auth = getAuth();
    const currentUser = auth.currentUser;

    console.log(originalImagePath, newImagePath);
    if (currentUser) {
      const idToken = await currentUser.getIdToken(true);

      await axios.post(
        "/api/adminUpdateImageInStorage",
        { originalImagePath, newImagePath, newPrompt },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
    } else {
      throw new Error("User not authenticated");
    }
  } catch (error) {
    console.error("Error updating image in storage:", error);
    throw error;
  }
}

export async function updateImageInStorage(
  originalImagePath: string,
  newImagePath: string,
  newPrompt: string
): Promise<void> {
  try {
    const auth = getAuth();
    const currentUser = auth.currentUser;

    console.log(originalImagePath, newImagePath);
    if (currentUser) {
      const idToken = await currentUser.getIdToken(true);

      await axios.post(
        "/api/updateImageInStorage",
        { originalImagePath, newImagePath, newPrompt },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
    } else {
      throw new Error("User not authenticated");
    }
  } catch (error) {
    console.error("Error updating image in storage:", error);
    throw error;
  }
}

export async function deleteImageInStorage(imagePath: string): Promise<void> {
  try {
    const auth = getAuth();
    const currentUser = auth.currentUser;

    if (currentUser) {
      const idToken = await currentUser.getIdToken(true);
      await axios.post(
        "/api/deleteImageInStorage",
        { imagePath },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
    } else {
      throw new Error("User not authenticated");
    }
  } catch (error) {
    console.error("Error deleting image in storage:", error);
    throw error;
  }
}

// clientApis.ts
import { ref, uploadBytes } from "firebase/storage";

export async function uploadImageToStorage(file: File): Promise<string> {
  const storage = getStorage();
  const auth = getAuth();
  const currentUser = auth.currentUser;

  if (!currentUser) {
    throw new Error("User not authenticated");
  }

  const storageRef = ref(storage, `temp/${currentUser.uid}/${file.name}`);
  await uploadBytes(storageRef, file);

  return file.name;
}

export async function mergeImage(file: File): Promise<string> {
  try {
    const fileName = await uploadImageToStorage(file);
    const auth = getAuth();
    const currentUser = auth.currentUser;

    if (!currentUser) {
      throw new Error("User not authenticated");
    }

    const idToken = await currentUser.getIdToken(true);

    const response = await axios.post(
      "/api/mergeImage",
      { fileName },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`,
        },
      }
    );

    return response.data?.imageUrl;
  } catch (error) {
    console.error("Error merging image:", error);
    throw error;
  }
}

export async function deleteGeneratedPage(imagePath: string): Promise<void> {
  try {
    const auth = getAuth();
    const currentUser = auth.currentUser;

    if (!currentUser) {
      throw new Error("User not authenticated");
    }

    const idToken = await currentUser.getIdToken(true);

    await axios.post(
      "/api/deleteGeneratedImage",
      { imagePath },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`,
        },
      }
    );
  } catch (error) {
    console.error("Error deleting generated page:", error);
    throw error;
  }
}
