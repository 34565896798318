"use client";
import { PLAN_KEYS } from "@/constants";
import { useAuthContext } from "@/context/authContext";
import { signOut } from "@/firebase/auth/signOut";
import { getManageSubscriptionLink } from "@/helpers/clientApis";
import { ActionIcon, Box, Burger, Button, Center, Container, Flex, Group, Menu, Modal, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconChevronDown, IconDashboard, IconLogout, IconSettings, IconUser } from "@tabler/icons-react";
import Image from "next/legacy/image";
import Link from "next/link";
import { useParams, useRouter } from "next/navigation";
import { useState } from "react";
import ColorSchemeToggle from "../ColorSchemeToggle";
import classes from "./HeaderMenu.module.css";

const links: {
  link: string;
  label: string;
  protected?: boolean;
  links?: { label: string; link: string; protected?: boolean }[];
}[] = [
  {
    link: "/coloring-pages",
    label: "Free Coloring Pages",
    // links: [{ label: "Coloring pages", link: "/coloring-pages", protected: false }],
  },
  { label: "Coloring Page Generator", link: "/ai-coloring-page-generator" },
  { label: "Photo To Coloring Page", link: "/photo-to-coloring-page-converter" },
];

const signedInLinks: {
  link: string;
  label: string;
  protected?: boolean;
  links?: { label: string; link: string; protected?: boolean }[];
}[] = [
  {
    link: "/coloring-pages",
    label: "Free Coloring Pages",
  },
  { label: "Dashboard", link: "/dashboard" },
];

export function Navbar() {
  const [opened, { toggle }] = useDisclosure(false);
  const { user } = useAuthContext();
  const router = useRouter();
  const [upgradeModalOpen, setUpgradeModalOpen] = useState(false);
  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const hideBranding = !!PLAN_KEYS[user?.plan || ""];

  const isPublicProfile = !!params?.username;
  const linkList = user ? signedInLinks : links.filter((link) => !link.protected);

  const items = linkList.map((link) => {
    const menuItems = link.links?.map((item: { label: string; link: string }) => (
      <Menu.Item key={item.label}>
        <Link href={item.link} key={item.link} className={classes.subLink}>
          {item.label}
        </Link>
      </Menu.Item>
    ));

    if (menuItems) {
      return (
        <Menu
          key={link.label}
          trigger="hover"
          transitionProps={{ exitDuration: 0 }}
          withinPortal
          aria-label="header menu"
        >
          <Menu.Target>
            <Center>
              <span className={classes.linkLabel}>{link.label}</span>
              <IconChevronDown size="0.9rem" stroke={1.5} />
            </Center>
          </Menu.Target>
          <Menu.Dropdown>{menuItems}</Menu.Dropdown>
        </Menu>
      );
    }

    return (
      <Link href={link.link} key={link.link} className={classes.link}>
        {link.label}
      </Link>
    );
  });

  const handleManageSubscription = async () => {
    setIsLoading(true);
    try {
      const link = await getManageSubscriptionLink();
      router.push(link);
    } catch (error) {
      console.error("Error getting manage subscription link:", error);
    }
    setIsLoading(false);
  };

  const userMenuItems = (
    <Menu trigger="hover" transitionProps={{ exitDuration: 0 }} withinPortal>
      <Menu.Target>
        <ActionIcon variant="" radius={"lg"}>
          <IconUser size=".9rem" />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item fz="md" onClick={() => router.push("/dashboard")} leftSection={<IconDashboard size="1rem" />}>
          Dashboard
        </Menu.Item>
        <Menu.Item
          fz="md"
          onClick={() => router.push("/account")}
          leftSection={<IconSettings size="1rem" />}
          disabled={isLoading}
        >
          Manage Account
        </Menu.Item>
        <Menu.Item
          fz="md"
          color="red"
          onClick={async () => {
            await signOut();
            router.push("/");
          }}
          leftSection={<IconLogout size="1rem" />}
        >
          Sign out
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );

  return (
    <header className={classes.header}>
      <Container size="xl" className={classes.headerInternalContainer}>
        {" "}
        <div className={classes.inner}>
          <Link href="/" className={classes.link}>
            <Box display="flex">
              <Image
                src="/crayons.svg"
                alt="KidPrints Logo"
                width={28} // Adjust the size as needed
                height={28} // Adjust the size as needed
              />
              <Text ml="5" size="lg" fw="500">
                KidPrints
              </Text>
            </Box>
          </Link>

          <Group gap={5} visibleFrom="sm" align="center" justify="center" fz="md">
            {items}
            {/* {user && !hideBranding && (
              <Button
                ml="xs"
                variant="gradient"
                gradient={{ from: "wumpus", to: "violet.4" }}
                onClick={() => setUpgradeModalOpen(true)}
              >
                Upgrade
              </Button>
            )} */}
            <ColorSchemeToggle />
            {user ? (
              userMenuItems
            ) : (
              <Group visibleFrom="sm">
                <Button onClick={() => router.push("/login")}>Log in</Button>
              </Group>
            )}
          </Group>
          <Flex hiddenFrom="sm">
            {/* {user && (
              <Button
                size="compact-sm"
                mr="xs"
                hiddenFrom="sm"
                variant="gradient"
                gradient={{ from: "wumpus", to: "violet.4" }}
                onClick={() => setUpgradeModalOpen(true)}
              >
                Upgrade
              </Button>
            )} */}
            <Menu>
              <Menu.Target>
                <Burger aria-label="menu icon" opened={opened} onClick={toggle} size="sm" />
              </Menu.Target>
              <Menu.Dropdown>
                <>
                  {linkList.map((link) => {
                    const menuItems = link.links?.map((item: { link: string; label: string }) => (
                      <Menu.Item>
                        <Link href={item.link} key={item.link} className={classes.subLink}>
                          {item.label}
                        </Link>
                      </Menu.Item>
                    ));
                    if (menuItems) {
                      return (
                        <>
                          <Menu.Label>{link.label}</Menu.Label>
                          {menuItems}
                        </>
                      );
                    }

                    return (
                      <Link href={link.link} key={link.link} className={classes.link}>
                        {link.label}
                      </Link>
                    );
                  })}
                  {user ? (
                    <>
                      <Menu.Item
                        fz="md"
                        color="red"
                        onClick={async () => {
                          await signOut();
                          router.push("/");
                        }}
                        leftSection={<IconLogout size={14} />}
                      >
                        Sign out
                      </Menu.Item>
                    </>
                  ) : (
                    <>
                      <Button variant="default" fullWidth onClick={() => router.push("/login")}>
                        Log in
                      </Button>
                    </>
                  )}
                </>
              </Menu.Dropdown>
            </Menu>
          </Flex>
        </div>
        <Modal opened={upgradeModalOpen} onClose={() => setUpgradeModalOpen(false)} title="Why upgrade?" size="lg">
          <Text size="sm" mb="md" fs="italic">
            By upgrading your account, you'll enjoy the following benefits:
          </Text>
          <Box mb="md">
            <Text size="sm" mb="xs">
              <strong>1. Remove KidPrints Branding:</strong> We'll remove the KidPrints branding from your public page.
            </Text>
            <Text size="sm" mb="xs">
              <strong>2. Support an Indie Founder:</strong> Your upgrade directly supports the development and growth of
              KidPrints, an indie project built with ❤️ by a former kid
            </Text>
            <Text size="sm">
              <strong>3. Early Adopter Perks:</strong> As an early adopter, you'll be grandfathered in for new features
              as they are released
            </Text>
          </Box>
          <Flex justify="flex-end">
            <Button variant="outline" onClick={() => setUpgradeModalOpen(false)} mr="sm">
              Maybe Later
            </Button>
          </Flex>
        </Modal>
      </Container>
    </header>
  );
}
