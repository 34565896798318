"use client";
// ContactForm.tsx
import { Button, Stack, Text, TextInput, Textarea } from "@mantine/core";
import { useForm } from "@mantine/form";
import axios from "axios";
import React, { useState } from "react";

type FormData = {
  subject?: string;
  emailAddress: string;
  message: string;
};

type ContactFormProps = {
  close?: () => void;
  subjectPlaceholder?: string;
  messagePlaceholder?: string;
};

const ContactForm: React.FC<ContactFormProps> = ({ close, subjectPlaceholder, messagePlaceholder }) => {
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const form = useForm<FormData>({
    initialValues: {
      subject: "",
      emailAddress: "",
      message: "",
    },
    validate: {
      emailAddress: (value) => !value.trim().length,
      message: (value) => !value.trim().length,
    },
  });

  const onSubmit = async (values: FormData) => {
    if (form.validate().hasErrors) {
      return;
    }

    const data = {
      attachments: [
        {
          blocks: [
            {
              type: "header",
              text: {
                type: "plain_text",
                text: "Contact Submission - KidPrints",
              },
            },
            {
              type: "section",
              fields: [
                {
                  type: "mrkdwn",
                  text: `*Subject:*\n ${values.subject}`,
                },
              ],
            },
            {
              type: "section",
              fields: [
                {
                  type: "mrkdwn",
                  text: `*Email:*\n${values.emailAddress}`,
                },
              ],
            },
            {
              type: "section",
              fields: [
                {
                  type: "mrkdwn",
                  text: `*Message:*\n${values.message}`,
                },
              ],
            },
          ],
        },
      ],
    };

    const webhookUrl = process.env.NEXT_PUBLIC_SLACK_WEBHOOK_URL || "";

    try {
      setLoading(true);
      await axios.post(webhookUrl, JSON.stringify(data), { withCredentials: false });
      setSubmitted(true);
      if (close) {
        close();
      }
    } catch (error) {
      console.error("Error sending message:", error);
    }
    setLoading(false);
  };

  return (
    <>
      {!submitted ? (
        <form onSubmit={form.onSubmit(onSubmit)}>
          <Stack gap="md">
            <TextInput
              label="Subject"
              placeholder={subjectPlaceholder || "Message from a fan"}
              {...form.getInputProps("subject")}
            />

            <TextInput
              withAsterisk
              label="Your Email"
              placeholder="yourEmail@gmail.com"
              {...form.getInputProps("emailAddress")}
            />
            <Textarea
              label="Message"
              withAsterisk
              autosize
              minRows={5}
              placeholder={
                messagePlaceholder || "I wanted to write and let you know how great I think this website is!"
              }
              {...form.getInputProps("message")}
              error={form.errors.message && "Message is required!"}
            />

            <Button fullWidth mt="md" mb="xs" color="blue" loading={loading} type="submit">
              Submit
            </Button>
          </Stack>
        </form>
      ) : (
        <Text size="lg" ta="center" mt="md">
          Your message was received. Thank you for reaching out!
        </Text>
      )}
    </>
  );
};

export default ContactForm;
