"use client";

import { MantineTheme, createTheme, em, rem } from "@mantine/core";
// import { PoppinsFont } from "./constants/fonts";

export const DEFAULT_MEDIA_QUERY = `(max-width: ${em(750)})`;

export const theme = createTheme({
  /* Put your mantine theme override here */
  primaryColor: "aqua",
  fontFamily: "Comic Sans MS",
  colors: {
    wumpus: [
      "#f4f4ff",
      "#dedeff",
      "#c8c8ff",
      "#b1b1ff",
      "#9b9bff",
      "#8585ff",
      "#6f6fff",
      "#5959ff",
      "#4343ff",
      "#2c2cff",
    ],
    aqua: [
      "#e0fbff",
      "#cbf2ff",
      "#9ae2ff",
      "#64d2ff",
      "#3cc5fe",
      "#23bcfe",
      "#09b8ff",
      "#00a1e4",
      "#0090cd",
      "#007cb5",
    ],
    white: [
      "#ffffff",
      "#ffffff",
      "#ffffff",
      "#ffffff",
      "#ffffff",
      "#ffffff",
      "#ffffff",
      "#ffffff",
      "#ffffff",
      "#ffffff",
    ],
  },
  fontSizes: {
    xs: rem(10),
    sm: rem(14),
    md: rem(17),
    lg: rem(19),
    xl: rem(23),
    xxl: rem(40),
    xxxl: rem(70),
  },
});

export const resolver = (theme: MantineTheme) => ({
  variables: {},
  light: {
    // "--mantine-Paper-body": "#FFFFFF",
    "--mantine-color-dimmed": theme.colors.dark[3],
    // "--mantine-color-body": theme.colors.gray[1],
  },
  dark: {
    // "--mantine-color-dimmed": theme.colors.dark[2],
  },
});
