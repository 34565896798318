"use client";
import { Box, Container, Text } from "@mantine/core";
import Image from "next/legacy/image";
import Link from "next/link";
import classes from "./Footer.module.css";
import { useParams } from "next/navigation";

const data = [
  {
    title: "Fun",
    links: [
      { label: "Free Coloring Pages", link: "/coloring-pages" },
      { label: "Coloring Page Generator", link: "/ai-coloring-page-generator" },
      { label: "Photo to Coloring Page", link: "/photo-to-coloring-page-converter" },
    ],
  },
  {
    title: "Company",
    links: [
      { label: "Contact", link: "/contact" },
      { label: "About", link: "/about" },
      { label: "Blog", link: "/blog" },
      { label: "Privacy Policy", link: "/privacy" },
      { label: "Terms of Service", link: "/terms" },
    ],
  },
];

export default function Footer() {
  const params = useParams();

  const isPublicProfile = !!params?.username;
  if (isPublicProfile) {
    return;
  }

  const groups = data.map((group) => {
    const links = group.links.map((link, index) => (
      <Link key={index} className={classes.link} href={link.link}>
        {link.label}
      </Link>
    ));

    return (
      <div className={classes.wrapper} key={group.title}>
        <Text className={classes.title}>{group.title}</Text>
        {links}
      </div>
    );
  });

  return (
    <footer className={classes.footer}>
      <Container className={classes.inner} size="xl">
        <div className={classes.logo}>
          <Box display="flex">
            <Image
              src="/crayons.png"
              alt="KidPrints Logo"
              width={28} // Adjust the size as needed
              height={28} // Adjust the size as needed
            />
            <Text ml="5" size="lg" fw="500">
              KidPrints
            </Text>
          </Box>
          <Text size="xs" c="dimmed" className={classes.description}>
            AI Generated Fun
          </Text>
          <Text fz={"xs"} c="dimmed">
            Made with ❤️ by a former kid.
          </Text>
        </div>
        <div className={classes.groups}>{groups}</div>
      </Container>
    </footer>
  );
}
